const SubjectPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/subjectPage/SubjectPage');
const SubjectPageSimple = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/subjectPage/SubjectPageSimple');
const SeaIndicatorPage = () => import('./templates/subjectPage/SeaIndicatorPage');
const EnvTargetsStartPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envTargetsStartPage/EnvTargetsStartPage');
const EnvTargetsResultAreaPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envTargetsResultAreaPage/EnvTargetsResultAreaPage');
const EnvTargetsTargetPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envTargetsTargetPage/EnvTargetsTargetPage');
const EnvTargetsIndicatorPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envTargetsIndicatorPage/EnvTargetsIndicatorPage');
const TransportPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/transportPage/TransportPage');
const ArticlePage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/articlePage/ArticlePage');
const DataPage = () => import('./templates/dataPage/DataPage');
const SearchResultPage = () => import('./templates/searchResultPage/SearchResultPage');
const CallToActionLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/callToActionLink/CallToActionLink');
const AccordionList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/accordionList/AccordionList');
const StandardMap = () => import('./components/maps/standardMap/StandardMap');
const Image = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/image/Image');
const YoutubeVideo = () => import(/* webpackChunkName: "DefaultComponents" */ './components/contentBlocks/youtubeVideo/YoutubeVideo');
const HighlightedLink = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLink/HighlightedLink');
const HighlightedLinkList = () => import(/* webpackChunkName: "DefaultComponents" */ './components/links/highlightedLinkList/HighlightedLinkList');
const SeparatedContentBlock = () => import('./components/contentBlocks/separatedContentBlock/SeparatedContentBlock');
const DownloadButton = () => import('./components/buttons/downloadButton/DownloadButton');
const FileDownloadList = () => import('./components/links/fileDownloadList/FileDownloadList');
const EnvironmentDataChartBlock = () => import(/* webpackChunkName: "EnvironmentTargetBlocks" */ './components/miljostatusComponents/environmentDataChartBlock/EnvironmentDataChartBlock');
const EnvironmentTargetBlock = () => import(/* webpackChunkName: "EnvironmentTargetBlocks" */ './components/miljostatusComponents/environmentTargetBlock/EnvironmentTargetBlock');
const EnvironmentIndicatorBlock = () => import(/* webpackChunkName: "EnvironmentTargetBlocks" */ './components/miljostatusComponents/environmentIndicatorBlock/EnvironmentIndicatorBlock');
const FactsBlock = () => import(/* webpackChunkName: "DefaultComponents" */ './components/miljostatusComponents/factsBlock/FactsBlock');
const StartPage = () => import(/* webpackChunkName: "FrontPage" */ './templates/startPage/StartPage');
const EnvTargetsListPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envTargetsListPage/EnvTargetsListPage');
const EnvIndicatorsListPage = () => import(/* webpackChunkName: "EnvironmentTargets" */ './templates/environmentTargetPages/envIndicatorsListPage/EnvIndicatorsListPage');
const EnvironmentStatePage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/environmentStatePage/EnvironmentStatePage');
const ErrorPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/errorPage/ErrorPage');
const EnvironmentDatasetsPage = () => import(/* webpackChunkName: "DefaultComponents" */ './templates/environmentDatasetsPage/EnvironmentDatasetsPage');
const IFrameMap = () => import('./components/maps/iframeMap/IFrameMap');

export const EPI_COMPONENTS = [
    {
        id: 'StartPage(1)',
        componentPromise: StartPage
    },
    {
        id: 'ArticlePage',
        componentPromise: ArticlePage
    },
    {
        id: 'DataPage',
        componentPromise: DataPage
    },
    {
        id: 'SubjectPage',
        componentPromise: SubjectPage
    },
    {
        id: 'SubjectPageSimple',
        componentPromise: SubjectPageSimple
    },
    {
        id: 'SeaIndicatorPage',
        componentPromise: SeaIndicatorPage
    },
    {
        id: 'TransportPage',
        componentPromise: TransportPage
    },
    {
        id: 'TransportPage(1)',
        componentPromise: TransportPage
    },
    {
        id: 'EnvironmentTargetStartPage',
        componentPromise: EnvTargetsStartPage
    },
    {
        id: 'ResultAreaPage',
        componentPromise: EnvTargetsResultAreaPage
    },
    {
        id: 'TargetPage',
        componentPromise: EnvTargetsTargetPage
    },
    {
        id: 'IndicatorPage',
        componentPromise: EnvTargetsIndicatorPage
    },
    {
        id: 'SearchPage',
        componentPromise: SearchResultPage
    },
    {
        id: 'CallToActionLinkBlock',
        componentPromise: CallToActionLink
    },
    {
        id: 'AccordionListBlock',
        componentPromise: AccordionList
    },
    {
        id: 'StandardMapBlock',
        componentPromise: StandardMap
    },
    {
        id: 'ImageBlock',
        componentPromise: Image
    },
    {
        id: 'YouTubeVideoBlock',
        componentPromise: YoutubeVideo
    },
    {
        id: 'HighlightedLinkBlock',
        componentPromise: HighlightedLink
    },
    {
        id: 'HighlightedLinkListBlock',
        componentPromise: HighlightedLinkList
    },
    {
        id: 'SeparatedContentBlock',
        componentPromise: SeparatedContentBlock
    },
    {
        id: 'DownloadButtonBlock',
        componentPromise: DownloadButton
    },
    {
        id: 'FileDownloadListBlock',
        componentPromise: FileDownloadList
    },
    {
        id: 'EnvironmentDataChartBlock',
        componentPromise: EnvironmentDataChartBlock
    },
    {
        id: 'EnvironmentTargetBlock',
        componentPromise: EnvironmentTargetBlock
    },
    {
        id: 'EnvironmentTargetIndicatorBlock',
        componentPromise: EnvironmentIndicatorBlock
    },
    {
        id: 'FactsBlock',
        componentPromise: FactsBlock
    },
    {
        id: 'TargetsPerResultAreaPage',
        componentPromise: EnvTargetsListPage
    },
    {
        id: 'IndicatorsPerResultAreaPage',
        componentPromise: EnvIndicatorsListPage
    },
    {
        id: 'EnvironmentStatePage',
        componentPromise: EnvironmentStatePage
    },
    {
        id: 'MiljostatusErrorPage',
        componentPromise: ErrorPage
    },
    {
        id: 'EnvironmentDatasetsPage',
        componentPromise: EnvironmentDatasetsPage
    },
    {
        id: 'IFrameMapBlock',
        componentPromise: IFrameMap
    }
];

export default EPI_COMPONENTS;
